import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nav from "./Nav.js"
import Index from "./MainIndex.js";
import Identity from "./Identity.js";
import Typography from "./Typography.js";
import Color from "./Color.js";
import Iconography from "./Iconography.js";
import Application from "./Application.js";
import Spacing from "./Spacing.js";
import Template from "./Template.js";
import Beam from "./Beam.js";

function App() {
  return (
    <Router>
    <Nav/>
      <Routes>
      <Route path="/" element={<Index />}></Route>
      <Route path="/identity" element={<Identity />}></Route>
      <Route path="/beam" element={<Beam />}></Route>
      <Route path="/typography" element={<Typography />}></Route>
      <Route path="/iconography" element={<Iconography />}></Route>
      <Route path="/color" element={<Color />}></Route>
      <Route path="/application" element={<Application />}></Route>
      <Route path="/spacing" element={<Spacing />}></Route>
      <Route path="/creatives" element={<Template />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
