import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu'
import { Link } from "react-router-dom";
import Logo from "./logo.svg"
import Burger from "./burger.svg"



function Nav() 
{
  const [isOpen, setOpen] = useState(false)
  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }
  return (
    <Menu     isOpen={isOpen}
    onOpen={handleIsOpen}
    onClose={handleIsOpen} customBurgerIcon={ <img src={Burger} alt="burger" /> }>
    <Link id="home" className="menu-item" onClick={closeSideBar} to="/">Index</Link>
    <Link id="about" className="menu-item" onClick={closeSideBar} to="/identity">Logo & Identity</Link>
    <Link id="about" className="menu-item" onClick={closeSideBar} to="/beam">Beam</Link>
    <Link id="contact" className="menu-item" onClick={closeSideBar} to="/typography">Typography</Link>
    <Link id="contact" className="menu-item" onClick={closeSideBar} to="/iconography">Iconography</Link>
    <Link id="contact" className="menu-item" onClick={closeSideBar} to="/color">Color</Link>
    <Link id="contact" className="menu-item" onClick={closeSideBar} to="/spacing">Spacing</Link>
    <Link id="contact" className="menu-item" onClick={closeSideBar} to="/application">Application</Link>
    <Link id="contact" className="menu-item" onClick={closeSideBar} to="/creatives">Creatives</Link>
    <img className='meritLogo' alt="logo" src={Logo} />
  </Menu>
  )
}

export default Nav