import "./App.css";

function App() {
  return (
    <>
     <iframe title="figLink"  
     width="100vw" 
     height="100vh" 
     frameBorder="0" 
     src="https://cute-kitten-b942cb.netlify.app/" 
     allowFullScreen>
     </iframe>
     </>
  );
}


export default App;