import "./App.css";
import Download from "./Download";
import ColorRef from "./ColorReferences.pdf.zip"

function App() {
  return (
    <>
    <Download things="color references" file={ColorRef}/>
     <iframe title="figLink"  
     width="100vw" 
     height="100vh" 
     frameBorder="0" 
     src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F8s3e4qQeKlB2HkZpFffpNY%2FMerit-Circle-Brand-Guidelines%3Fpage-id%3D216%253A634%26node-id%3D216%253A1842%26viewport%3D915%252C391%252C0.09%26scaling%3Dscale-down-width%26hide-ui%3D1" 
     allowFullScreen>
     </iframe>
     </>
  );
}


export default App;