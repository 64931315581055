import "./App.css";
import Download from "./Download";
import Typefaces from './swisstypefaces.zip'

function App() {
  return (
    <>
    <Download things="fonts" file={Typefaces} />
     <iframe title="figLink"  
     width="100vw" 
     height="100vh" 
     frameBorder="0" 
     src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F8s3e4qQeKlB2HkZpFffpNY%2FMerit-Circle-Brand-Guidelines%3Fpage-id%3D1%253A837%26node-id%3D7%253A15%26viewport%3D122%252C5441%252C0.46%26scaling%3Dscale-down-width%26hide-ui%3D1" 
     allowFullScreen>
     </iframe>
     </>
  );
}


export default App;